export default [
    {
        path: '/about/intro',
        routeName: 'About-Intro',
        name: 'About',
        isLoading: false,
    },
    {
        name: 'Market',
        children: [

            {
                path: '/market/list?page=1&order=3&sub_category_no=217,',
                routeName: 'MarketNftList',
                name: 'Wine NFT',
                isLoading: false,
                children: [
                    {
                        routeName: 'NftDetail'
                    }
                ]
            },
            {
                path: '/market/membership/list?page=1&order=3&sub_category_no=219,',
                routeName: 'MarketMembershipNftList',
                name: 'Membership NFT',
                isLoading: false,
                children: [
                    {
                        routeName: 'MembershipDetail'
                    }
                ]
            },
        ]
    },
    {
        path: '/membership',
        routeName: 'membershipMinting',
        name: 'Membership',
        isLoading: false
    },
    {
        name: 'Help',
        children: [
            {
                path: '/cs/notice',
                routeName: 'Notice',
                name: 'Notice',
                isLoading: false,
                children: [
                    {
                        routeName: 'NoticeDetail'
                    }
                ]
            },
            {
                path: '/cs/faq',
                routeName: 'FAQ',
                name: 'FAQ',
                isLoading: false,
            },
            {
                path: '/cs/guide',
                routeName: 'Guide',
                name: 'User Guide',
                isLoading: false,
            },
            {
                path: '/cs/press',
                routeName: 'Press',
                name: 'Press',
                isLoading: false,
            },
        ]
    },
    {
        name: 'My Page',
        children: [
            {
                path: '/users/mypage',
                routeName: 'NftList',
                name: 'Wine NFT',
                isLoading: false,
            },
            {
                path: '/users/mypage/membership',
                routeName: 'MembershipNftList',
                name: 'Membership NFT',
                isLoading: false
            },
	/*
            {
                name: 'Activity',
                isLoading: true
            },
	*/
            {
                path: '/users/mypage/profile',
                routeName: 'Profile',
                name: 'Profile',
                isLoading: false,
                children: [
                    {
                        routeName: 'EditProfile'
                    }
                ],
            },
        ]
    },
]
